import { DocumentData, QuerySnapshot } from "firebase/firestore";

export function getDataFromCollectionSnapshot<T>(
  isT: (obj: unknown) => obj is T,
  snapshot?: QuerySnapshot<DocumentData>,
) {
  if (!snapshot) return [];

  return snapshot.docs.reduce<T[]>((result, doc) => {
    if (doc.exists()) {
      const data = doc.data();

      if (isT(data)) {
        result.push(data);
      }
    }

    return result;
  }, []);
}
