"use client";
import { FC, useState } from "react";
import { ENVIRONMENT } from "../consts/ENVIRONMENT";

export const AppDevelopmentWarningView: FC = () => {
  const [isVisible, setIsVisible] = useState(
    ENVIRONMENT.isUsingProductionFirebaseLocally,
  );

  if (!isVisible) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        backgroundColor: "yellow",
        color: "black",
        borderTop: "1px black dashed",
        width: "100%",
        zIndex: "999",
        fontSize: "0.5rem",
        padding: "0.3rem",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          position: "relative",
        }}
      >
        ⚠️ <strong>WARNING!</strong> Firebase Emulator is not connected. Using
        project <strong>{ENVIRONMENT.firebaseConfig.projectId}</strong>. Set
        NEXT_PUBLIC_CONNECT_FIREBASE_FUNCTIONS_EMULATOR=true in .env to connect
        to the emulator.
        <button
          onClick={() => setIsVisible(false)}
          title="close"
          style={{
            cursor: "pointer",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          x
        </button>
      </h1>
    </div>
  );
};
