import { isOrganization, Organization } from "@snubes/snubes-types";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { create } from "zustand";
import { handleError } from "../../Common/helpers/handleError";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { getDataFromCollectionSnapshot } from "../../Firebase/helpers/getDataFromQuerySnapshot";

interface MyOrganizationsState {
  organizations?: Organization[];
  isInitializing: boolean;
  subscribe: (organizationsIds: string[]) => void;
  unsubscribe?: () => void;
}

export const useMyOrganizations = create<MyOrganizationsState>((set) => ({
  isInitializing: true,
  subscribe: (organizationIds) => {
    if (!organizationIds.length) {
      return;
    }
    const unsubscribeFirestore = onSnapshot(
      query(
        getCollectionRef("organizations"),
        where(documentId(), "in", organizationIds),
        where("isDeleted", "==", false),
      ),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const organizations = getDataFromCollectionSnapshot(
          isOrganization,
          snapshot,
        );

        set({
          organizations,
          isInitializing: false,
        });
      },
      (error) => {
        handleError(error).logAnd().toast();
        set({ organizations: undefined, isInitializing: false });
      },
    );

    set({
      unsubscribe: () => {
        set({ organizations: undefined, isInitializing: true });
        unsubscribeFirestore();
      },
    });
  },
}));
