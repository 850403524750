import { isUser, User } from "@snubes/snubes-types";
import { onSnapshot } from "firebase/firestore";
import { create } from "zustand";
import { handleError } from "../../Common/helpers/handleError";
import { getDataFromDocumentSnapshot } from "../../Firebase/helpers/getDataFromDocumentSnapshot";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { setupSentryUser } from "../../setup/setupSentry";

interface MyUserState {
  user?: User;
  isInitializing: boolean;
  subscribe: (userId: string) => void;
  unsubscribe?: () => void;
}

export const useMyUser = create<MyUserState>((set) => ({
  isInitializing: true,
  subscribe: (userId) => {
    const unsubscribeFirestore = onSnapshot(
      getDocRef("users", userId),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const user = getDataFromDocumentSnapshot(isUser, snapshot);

        setupSentryUser(user);
        set({
          user,
          ...(user && { isInitializing: false }),
        });
      },
      (error) => {
        handleError(error).logAnd().toast();
        set({ user: undefined, isInitializing: false });
      },
    );

    set({
      unsubscribe: () => {
        set({ user: undefined, isInitializing: true });
        unsubscribeFirestore();
      },
    });
  },
}));
