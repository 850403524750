"use client";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FC, PropsWithChildren } from "react";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { PALETTE } from "../consts/PALETTE";
import { THEME } from "../consts/THEME";
import { AppDirEmotionCacheProvider } from "./AppDirEmotionCacheProvider";

export const AppThemeProvider: FC<PropsWithChildren> = (props) => {
  const primaryColor = useSelectedOrganization(
    (org) => org?.layout?.primaryColor,
  );
  const organizationTheme = createTheme({
    ...THEME,
    palette: {
      ...PALETTE,
      ...(primaryColor && {
        primary: { ...PALETTE.primary, main: primaryColor },
      }),
    },
  });

  return (
    <AppDirEmotionCacheProvider options={{ key: "mui" }}>
      <ThemeProvider theme={primaryColor ? organizationTheme : THEME}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </AppDirEmotionCacheProvider>
  );
};
