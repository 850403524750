import { LinkProps } from "@mui/material/Link";
import { createTheme } from "@mui/material/styles";
import { Manrope, Roboto_Mono } from "next/font/google";
import { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { APP_SIDEBAR_WIDTH } from "./APP_SIDEBAR_WIDTH";
import { PALETTE } from "./PALETTE";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    regular: true;
  }

  interface ButtonPropsSizeOverrides {
    tiny: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
    mono?: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
    mono?: React.CSSProperties;
  }
}

// Add body3 to Typography variants and remove h6
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    caption1: true;
    caption2: true;
    mono: true;
    h6: false;
  }
}

/**
 * @see https://mui.com/material-ui/guides/routing/#global-theme-link
 */
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehavior.displayName = "LinkBehavior";

/**
 * @see https://nextjs.org/docs/basic-features/font-optimization
 */
export const fontManrope = Manrope({
  weight: ["300", "400", "500", "600", "700", "800"],
  subsets: ["latin"],
  display: "swap",
  fallback: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
});

export const fontRobotoMono = Roboto_Mono({
  weight: ["500"],
  subsets: ["latin"],
  display: "swap",
  fallback: [
    "Lucida Console",
    "Lucida Sans Typewriter",
    "monaco",
    "Bitstream Vera Sans Mono",
    "monospace",
  ],
});

const fontWeight = {
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  medium: 500, // default
};

const defaultFontConfig = {
  fontSize: 14,
  lineHeight: 20 / 14,
  fontWeight: fontWeight.medium,
};

export const THEME = createTheme({
  palette: PALETTE,
  /**
   * Due to a bug in MUI we have to use this function instead of the array like this:
   * spacing: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512, 1024],
   *
   * Source:
   * https://github.com/mui/material-ui/issues/29677
   *
   * Reason:
   * Some components will use non-integer values for spacing and this breaks the spacing array.
   *
   * Solution:
   * Use a function instead of an array and calulate the spacing based on the factor
   *
   * Result:
   * theme.spacing(1)   // 4px
   * theme.spacing(1.5) // 6px
   * theme.spacing(2)   // 8px
   * theme.spacing(2.5) // 12px
   */
  spacing: (factor: number) => {
    const values = [0, 4, 8, 16, 24, 32, 64, 128, 256, 512, 1024, 2048];
    const index = Math.floor(factor);
    const currentSpace = values[index];
    const nextSpace = values[index + 1] || currentSpace * 2;
    const space = currentSpace + (nextSpace - currentSpace) * (factor - index);
    return `${space}px`;
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1720,
    },
  },
  typography: {
    fontWeightBold: fontWeight.extraBold,
    fontWeightMedium: fontWeight.bold,
    fontWeightRegular: fontWeight.semiBold,
    fontWeightLight: fontWeight.medium,
    fontFamily: fontManrope.style.fontFamily,

    h1: {
      fontSize: 40,
      lineHeight: 1.5,
      fontWeight: fontWeight.extraBold,
      // TODO: do we need responsive font sizes? if yes we have to add this
      // '@media (min-width:600px)': {
      //  fontSize: 50,
      // },
    },
    h2: {
      fontSize: 32,
      lineHeight: 44 / 32,
      fontWeight: fontWeight.extraBold,
    },
    h3: {
      fontSize: 20,
      lineHeight: 27 / 20,
      fontWeight: fontWeight.extraBold,
    },
    h4: {
      fontSize: 16,
      lineHeight: 22 / 16,
      fontWeight: fontWeight.extraBold,
    },
    h5: {
      fontSize: 14,
      lineHeight: 19 / 14,
      fontWeight: fontWeight.extraBold,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 22 / 16,
      fontWeight: fontWeight.semiBold,
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 19 / 14,
      fontWeight: fontWeight.semiBold,
    },
    // THIS variant body1 is used by MUI as the default font for body and html tag as well. Will be applied to all unstyled text and inputs
    // When we set this bigger then default size then we have to style all inputs and other components manually
    body1: {
      ...defaultFontConfig,
    },
    body2: {
      fontSize: 16,
      lineHeight: 22 / 16,
      fontWeight: fontWeight.semiBold,
    },
    body3: {
      fontSize: 18,
      lineHeight: 25 / 18,
      fontWeight: fontWeight.bold,
    },
    button: {
      fontSize: 16,
      lineHeight: 22 / 16,
      fontWeight: fontWeight.medium,
    },
    caption1: {
      fontSize: 12,
      lineHeight: 16 / 12,
      fontWeight: fontWeight.bold,
    },
    caption2: {
      fontSize: 12,
      lineHeight: 16 / 12,
      fontWeight: fontWeight.medium,
    },
    mono: {
      fontSize: 16,
      lineHeight: 22 / 16,
      fontWeight: fontWeight.medium,
      fontFamily: fontRobotoMono.style.fontFamily,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          height: "100%",
          ...defaultFontConfig,
        },
        "#__next": {
          height: "100%",
        },
        [`.${fontManrope.className}`]: {
          height: "100%",
        },
        ".firebase-emulator-warning": {
          display: "none",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: PALETTE.grey[100],
          width: `${APP_SIDEBAR_WIDTH}px`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `16px 24px`,
        },
      },
    },
    // credits: https://mui.com/material-ui/guides/routing/#global-theme-link
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
        LinkComponent: LinkBehavior,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "unset",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 700,
          padding: `12px 16px`,
          minWidth: "unset",
          minHeight: "unset",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover:hover": {
            backgroundColor: PALETTE.grey[50],
          },
          th: {
            borderBottom: `1px solid ${PALETTE.divider}`,
          },
          td: {
            borderBottom: `1px solid ${PALETTE.divider}`,
          },
          "&:last-child td": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          borderRadius: 3,
          whiteSpace: "nowrap",
          "&&": {
            textTransform: "inherit",
          },
        },
      },
      variants: [
        {
          props: { variant: "regular" },
          style: {
            backgroundColor: PALETTE.grey[50],
          },
        },
        {
          props: { size: "tiny" },
          style: {
            padding: "3px 10px",
          },
        },
        {
          props: { size: "small" },
          style: {
            padding: "8px 16px",
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: 16,
          },
        },
        {
          props: { size: "large" },
          style: {
            padding: 22,
          },
        },
      ],
    },
  },
});
