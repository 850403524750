import { Color, PaletteOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypeText extends Color {
    primary: string;
  }

  interface PaletteColor extends Color {
    main: string;
  }
}

export const PALETTE = {
  mode: "light",
  primary: {
    main: "#295df9",
    200: "#8c9eff",
    50: "#e7e8ff",
  },
  secondary: {
    main: "#00a382",
    50: "#e1f4f0",
  },
  text: {
    primary: "#001226",
    secondary: "#444F65",
    disabled: "#E6E6E6",
    600: "#576279",
    500: "#7E89A2",
  },
  background: {
    paper: "#ffffff",
    default: "#F9FBFC",
  },
  grey: {
    500: "#A1A3A4",
    400: "#C0C2C2",
    300: "#e2e4e5",
    200: "#eff1f2",
    100: "#f4f6f7",
    50: "#F9FBFC",
  },
  info: {
    main: "#878efb",
    200: "#c4cdff",
    100: "#dbe0ff",
    50: "#f1f3ff",
  },
  warning: {
    main: "#ff9900",
    700: "#f57d00",
    50: "#fff3e0",
  },
  error: {
    main: "#ef5350",
    900: "#B71B1C",
    50: "#ffebee",
  },
  success: {
    main: "#4CAF50",
  },
  divider: "#eff1f2",
} satisfies PaletteOptions;
