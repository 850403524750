import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";

export const app = initializeApp({
  ...ENVIRONMENT.firebaseConfig,
  // Let's use a dedicated project for emulator to prevent writes to staging
  ...(ENVIRONMENT.firebaseConfig.connectFirebaseFunctionsEmulator && {
    projectId: "snubes-local",
    storageBucket: "snubes-local.appspot.com",
  }),
});

const region = ENVIRONMENT.firebaseConfig.connectFirebaseFunctionsEmulator
  ? "us-central1"
  : ENVIRONMENT.firebaseRegion;

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, region);
export const storage = getStorage(app);
export const analytics =
  typeof window !== "undefined" && ENVIRONMENT.firebaseConfig.measurementId
    ? getAnalytics(app)
    : null;

/**
 * To use this you have to set NEXT_PUBLIC_CONNECT_FIREBASE_FUNCTIONS_EMULATOR=true in .env
 */
if (ENVIRONMENT.firebaseConfig.connectFirebaseFunctionsEmulator) {
  const host = "localhost";

  connectFunctionsEmulator(functions, host, 5001);
  connectFirestoreEmulator(db, host, 8080);
  connectAuthEmulator(auth, `http://${host}:9099`);
  connectStorageEmulator(storage, host, 9199);
}
if (ENVIRONMENT.isUsingProductionFirebaseLocally) {
  console.group("%cWARNING!", "font-size: 16px; color: #ff0000;");
  console.warn(
    [
      `Firebase Emulator is not connected.`,
      `Using project ${ENVIRONMENT.firebaseConfig.projectId}`,
      `Set NEXT_PUBLIC_CONNECT_FIREBASE_FUNCTIONS_EMULATOR=true in .env to connect to the emulator`,
    ].join("\n"),
  );
  console.groupEnd();
}
