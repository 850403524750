import { Organization } from "@snubes/snubes-types";
import { useCallback } from "react";
import { setupSentryOrganization } from "../../setup/setupSentry";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { getSelectedOrganizationId } from "../helpers/getSelectedOrganizationId";
import { useMyOrganizations } from "./useMyOrganizations";

type Selector<T> = (state?: Organization) => T;

export function useSelectedOrganization(
  selector?: never,
): Organization | undefined;
export function useSelectedOrganization<T>(selector: Selector<T>): T;
export function useSelectedOrganization<T>(selector?: Selector<T>) {
  const selectedOrganizationId = useMyUser((state) =>
    getSelectedOrganizationId(state.user),
  );

  return useMyOrganizations(
    useCallback(
      (state) => {
        const selectedOrganization =
          state.organizations?.find(
            (organization) => organization.id === selectedOrganizationId,
          ) || state.organizations?.[0];

        // TODO: find a better place to setup sentry organization
        if (selectedOrganization) {
          setupSentryOrganization(selectedOrganization);
        }

        return selector ? selector(selectedOrganization) : selectedOrganization;
      },
      [selectedOrganizationId, selector],
    ),
  );
}
